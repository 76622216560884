import './App.css';

function App() {
  return (
    <div className="App">
      <div id="content">
      <header>
      </header>

      <div className="App-logo" />

      <footer>
        Courtesy of <a href="http://pixelparasol.com"> Pixel Parasol</a>
      </footer>
    </div>
    </div>
  );
}

export default App;
